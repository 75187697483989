// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// see https://github.com/nrwl/nx/issues/208#issuecomment-475911897
// NOTE: class solution does not work for ngmodule imports, that is why object is also exported
import { Environment, OAuthEnvironment, PerformanceSentryConfig } from '@brightside-web/micro/core/environment';

/**
 * PLEASE DO NOT ADD ANYTHING HERE THAT CAN BE FETCHED FROM FIREBASE REMOTE CONFIG
 * THIS SHOULD ONLY BE FOR INITIALIZING SERVICES THAT CANNOT DEPEND ON FRC BEING AVAILABLE YET
 */
export const env = {
  //Pre Dev Test Instance:
  awsConnectSnippetId:
    'QVFJREFIZ2FmaHlLVlhzMXdOM213U09zSVJiNFhEdjVTNFI4YWdlakN5c2NqL2w4R2dFck5JVjlHZkR6NGRqV25tdW9BZUhHQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNOC9xa01pNU1FVG4vQlpaM0FnRVFnQ3ZDdzluejR4cDhGY3ZuNWl0eHZrQy9kOXJpUFk5ZmVuWTgzRWZVQ1FQelBnMHhzM2FIbnpZV3Vwdko6Okx0K2k5em5jajVrVWc0dVZxQndObUNCYnd4VitEaEUraFlrN3VBV1N2RVRpOUFDQ1Zzbnk5QVBjanBYSWo4TFhXd2RmekNOTUdUS2lWUVBkYkVJcGdPa0NSYUdKdDlKR1NzZysxNW9VYit3dFB0MjRONDRTTWxsUWZpR3YxbENkV0I3N21nNmpSWFEya3FtS1E2NUQ1NkhMTEg2cEJzcz0=',
  awsConnectWidgetId: '7f08b230-e705-4357-9c6c-57d95e1edebe',
  amplitude: {
    apiKey: 'ab378e3598dcf40c67ee641b16f8de23',
  },
  performance: {
    sentry: {
      dsn: 'https://a6017af255a6428b81626003a57ba2cc@o4505166779187200.ingest.sentry.io/4505166830895104',
      environment: "dev",
    },
  },
  sprigId: '8qqP2LSK4',
  hostedDomain: 'gobrightsidedev',
  awsmobile: {
    aws_project_region: 'us-west-2',
    endpoints: {
      'api-mobile': {
        name: 'api-mobile',
        endpoint: 'https://api.dev.gobrightside.com/v1',
      },
      'api-mobile-noauth': {
        name: 'api-mobile-noauth',
        endpoint: 'https://api.dev.gobrightside.com/v1',
      },
      'cdn-mobile-noauth': {
        name: 'cdn-mobile-noauth',
        endpoint: 'https://cdn.dev.gobrightside.com',
      },
    },
  },
  crmUrl: 'https://brightsidebenefitdev--bsbdev.lightning.force.com/lightning/r/Contact/%1$s/view',
  cdnUrl: 'https://cdn.dev.gobrightside.com/',
  firebase: {
    apiKey: 'AIzaSyDQ0ig6nM9ZHo_ZSfUSpcojgCyNxfaUgvo',
    authDomain: 'healhtydollar-android.firebaseapp.com',
    databaseURL: 'https://healhtydollar-android.firebaseio.com',
    projectId: 'healhtydollar-android',
    storageBucket: 'healhtydollar-android.appspot.com',
    messagingSenderId: '812435151064',
    appId: '1:812435151064:web:4fd9062832a2090a005fad',
    measurementId: 'G-5Q15PFP6TJ',
  },
  intercomAppId: 'grdau986',
  googleTagManagerId: 'GTM-M6MVX2L',
  googleTrackingId: 'UA-112701913-5',
  production: false,
  baseEnv: 'dev',
  featureFlags: {},
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('package.json').version,
  featureFlagApp: {
    clientSideId: '654615b0c00e6012abdba910'
  },
  smfaDynamicLink: 'https://link.gobrightside.com/verify-smfa-dev',
  socure: '8884adad-3139-48f3-a417-2249e14db442',
  auth0: {
    domain: 'login.dev.gobrightside.com',
    clientId: '0ODvgDRGb5hmSnuSwFHFDfNwXna5VsGV',
    audience: 'https://api.dev.gobrightside.com/v1/'
  },
  chat: {
    organizationId: '00D790000000P7T',
    developerName: 'Messaging_for_Web',
    url: 'https://brightsidebenefit--dev.sandbox.my.site.com/ESWMessagingforWeb1723789922537',
    scrt2URL: 'https://brightsidebenefit--dev.sandbox.my.salesforce-scrt.com',
    chatScript: 'https://brightsidebenefit--dev.sandbox.my.site.com/ESWMessagingforWeb1723789922537/assets/js/bootstrap.min.js'
  }
};

class EnvironmentImpl implements Environment {
  auth0 = env.auth0;
  awsConnectSnippetId = env.awsConnectSnippetId;
  awsConnectWidgetId = env.awsConnectWidgetId;
  hostedDomain = env.hostedDomain;
  awsmobile = env.awsmobile;
  intercomAppId = env.intercomAppId;
  production = env.production;
  baseEnv = env.baseEnv;
  firebase = env.firebase;
  crmUrl = env.crmUrl;
  cdnUrl = env.cdnUrl;
  googleTagManagerId = env.googleTagManagerId;
  googleTrackingId = env.googleTrackingId;
  sprigId = env.sprigId;
  featureFlags = env.featureFlags;
  amplitude = env.amplitude;
  appVersion = env.appVersion;
  performance = env.performance;
  featureFlagApp = env.featureFlagApp;
  smfaDynamicLink= env.smfaDynamicLink;
  socure = env.socure;
  chat = env.chat;
}
export const environment = new EnvironmentImpl();

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
